import React, { Component } from 'react'
import { Button,ControlGroup, Popover, Position, Text, Tag, Menu, MenuItem, Intent } from '@blueprintjs/core';
import { DateRangePicker } from "@blueprintjs/datetime";
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import ListPicker from 'components/ListPicker';
import SearchDateRangePicker from 'components/SearchDateRangePicker';
import ClientAutocomplete from 'components/ClientAutocomplete';
import TechnicianPicker from 'components/TechnicianPicker';
import LocationAutocomplete from 'components/LocationAutocomplete';
import moment from 'moment-timezone';
import Toast from 'helpers/toaster';

const options = [
  { label: 'Draft', value: 'draft', selected: false },
  { label: 'Approved', value: 'approved', selected: false },
  { label: 'Submitted', value: 'submitted', selected: false },
  { label: 'Sent', value: 'sent', selected: false },
  { label: 'On Hold', value: 'on_hold', selected: false },
  { label: 'Rejected', value: 'rejected', selected: false }
];

@inject('store', 'quoteStore', 'viewStore','jobStore','workReportStore') @observer
class Quote extends Component {

  @observable startDate = null;
  @observable endDate = null;
  @observable selectedClient = '';

  @action
  onDateRangeChange(range) {
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        created_at: { 
          gte: moment(range[0]).startOf('day').unix(),
          lte: moment(range[1]).endOf('day').unix()
        }
      }
    }
    this.startDate = range[0];
    this.endDate = range[1];
    this.search()
  }

  dateRangePicker = () => {
    return (
      <DateRangePicker 
        value={[this.search.startDate, this.search.endDate]}
        formatDate={(d) => { return d.toDateString() }} 
        parseDate={(d) => { console.log(d); return new Date(d) }}
        allowSingleDayRange={true}
        onChange={this.onDateRangeChange.bind(this)}
      />
    )
  }

  download_bulk_excel() {
    this.props.workReportStore.download_bulk_excel(this.props.viewStore.quoteSearch)
  }

  download_bulk_pdf() {
    this.props.workReportStore.download_bulk_pdf(this.props.viewStore.quoteSearch)
      .then(this.onSuccessfullGenerate.bind(this))
      .catch((error) => {})
  }

  onSuccessfullGenerate(response) {
    Toast.show({ icon: 'thumbs-up', message: response.data.message, intent: Intent.SUCCESS })
  }

  renderSelectedLocation = () => {
    if (this.search.location) {
      return <Text ellipsize={true} style={{ width: '250px' }}>{this.search.location}</Text>
    } else {
      return 'Location';
    }
  }

  @action
  onQuoteStatusChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        status: selectedValues
      }
    }
  }

  onGrandTotalChange(e) {
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    quoteSearch.where.grand_total[e.target.id] = e.target.value;
  }

  @action
  onJobNumberChange(e) {
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        event_number: e.target.value
      }
    }
  }

  onEnter(event) {
    if (event.key === "Enter") {
      this.search()
    }
  }

  onServiceTypeChange(selections) {
    let selectedValues = selections.map((status) => status.value);
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        service_type_id: selectedValues
      }
    }
  }

  onTechnicianChange(technicians) {
    let selectedValues = technicians.map((status) => status.value);
    let {
      props: {
        viewStore: {
          quoteSearch
        }
      }
    } = this;
    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        created_by_id: selectedValues
      }
    }
  }


  @action
  onLocationSelect(client) {    
    let {
      props: {
        viewStore: {
          quoteSearch,
          quoteSearch: {
            where: {
              client_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.quoteSearch = {
        ...quoteSearch,
        where: {
          ...quoteSearch.where,
          client_id:  client.attributes.client_id,
          location_id: client.attributes.id
        }
      }
       
    } else {
      this.props.viewStore.quoteSearch = {
        ...quoteSearch,
        where: {
          ...whereWithNoClientId
        }
      }
    }
    
  }

  @action
  onClientSelect(client) {
    let { object} = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          quoteSearch,
          quoteSearch: {
            where: {
              client_id,
              ...whereWithNoClientId
            }
          }
        }
      }
    } = this;
    if (client) {
      this.props.viewStore.quoteSearch = {
        ...quoteSearch,
        where: {
          ...quoteSearch.where,
          client_id: client.id,
          location_id: object.location_id
        }
      }

      object.client_id = client.id 
    } else {
      this.props.viewStore.quoteSearch = {
        ...quoteSearch,
        where: {
          ...whereWithNoClientId,
          client_id: null,
          location_id: null
        }
      }
    }
  }

  

  @action
  search() {
    let viewStore = this.props.viewStore;
    const {currentUser} = viewStore;
    viewStore.quoteSearch.page = 1;
    if (currentUser?.attributes?.role === 'technician') {
      viewStore.technicianQuotes();
    } else {
      viewStore.quotes();
    }
  }

  
  onLocationClear = () => {
    let { object } = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          quoteSearch,
        }
      }
    } = this;
    object.location_id = null;
    object.location = null;

    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        location_id: null
      }
    }
  }

  onClientClear = () => {
    let { object } = this.props.jobStore.persistJob;
    let {
      props: {
        viewStore: {
          quoteSearch,
        }
      }
    } = this;
    object.client_id = null;
    object.client = null;

    this.props.viewStore.quoteSearch = {
      ...quoteSearch,
      where: {
        ...quoteSearch.where,
        client_id: null
      }
    }

    this.onLocationClear();
  }

  render() {
    const {
      where
    } = this.props.viewStore.quoteSearch;
    const { 
      currentUser
    } = this.props.viewStore;
    let startDate = moment.unix(parseInt(where.created_at.gte)).toDate();
    let endDate = moment.unix(parseInt(where.created_at.lte)).toDate();
    let { object } = this.props.jobStore.persistJob;
    return (
      <nav className="bp3-navbar search bg-transparent navbar-overflow">
        <div>
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-button-group">
              <div className="bp3-control-group">
                <Popover
                  minimal={true}
                  content={
                    <SearchDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={this.onDateRangeChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    {(!startDate || !endDate) && `Date Range`}
                    {startDate && endDate && `${startDate.toDateString()} - ${endDate.toDateString()}`}
                  </Button>
                </Popover>
                
                <ClientAutocomplete
                  selectedClientId={where && where.client_id}
                  onClientSelect={this.onClientSelect.bind(this)}
                />
                { 
                  where && where.client_id &&                   
                    <ControlGroup fill={false} vertical={false}>
                      <LocationAutocomplete
                        scope={{ client_id: where.client_id } }
                        style={{ width: '400px' }}
                        inputProps={{ fill: true, placeholder: 'Start typing location..' }}
                        onLocationClear={this.onLocationClear.bind(this)}
                        value={object.location}
                        selectedLocationId={where && where.location_id}
                        onLocationSelect={(location) => { 
                          if(object && location){
                            object.location_id = location.id;
                            object.location = location
                            this.onLocationSelect(location)
                          }else{
                            this.onLocationClear();
                          }
                        }}
                      />
                    </ControlGroup>                    
                }
              {currentUser?.attributes?.role !== 'technician' && 
                <TechnicianPicker
                  selectedOptions={(where && where.created_by_id) || []}
                  onChange={this.onTechnicianChange.bind(this)}
                  includeAdmins
                  showCreatedBy
                  includeSelectAll
                />}
                
                <Popover
                  minimal={true}
                  content={
                    <ListPicker
                      selectedOptions={(where && where.status) || []}
                      options={options}
                      onChange={this.onQuoteStatusChange.bind(this)}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                  usePortal={true} >
                  <Button rightIcon='caret-down'>
                    Status
                    <span>&nbsp;</span>
                    <Tag minimal={true} round={true}>{(where && where.status && where.status.length) || 0}</Tag>
                  </Button>
                </Popover>

                <input 
                  type="text"
                  className="bp3-input"
                  placeholder="Job Number"
                  onChange={this.onJobNumberChange.bind(this)}
                  defaultValue={where.event_number}
                  onKeyPress={this.onEnter.bind(this)}
                />

                <input 
                  style={{width: '100px'}}
                  id="gte"
                  type="text"
                  className="bp3-input"
                  placeholder="Min. amount"
                  onChange={this.onGrandTotalChange.bind(this)}
                  defaultValue={where.grand_total.gte}
                  onKeyPress={this.onEnter.bind(this)}
                />
                <input 
                  style={{width: '100px'}}
                  id="lte"
                  type="text"
                  className="bp3-input"
                  placeholder="Max. amount"
                  onChange={this.onGrandTotalChange.bind(this)}
                  defaultValue={where.grand_total.lte}
                  onKeyPress={this.onEnter.bind(this)}
                />   

                <Button rightIcon='search' text='Search' onClick={this.search.bind(this)} />
              </div>
            </div>
          </div>
          <div className="bp3-navbar-group bp3-align-right">
            <Popover 
              minimal={true}
              content={
                <Menu>
                  <MenuItem text="Download Excel/CSV" onClick={this.download_bulk_excel.bind(this)}/>
                  <MenuItem text="Download PDF" onClick={this.download_bulk_pdf.bind(this)}/>
                </Menu>
              } 
              position={Position.BOTTOM_LEFT}
              usePortal={true}>
              <Button rightIcon='caret-down' >
                Download Excel/CSV
              </Button>
            </Popover>
          </div> 
        </div>
      </nav>
    )
  }
}

export default Quote;