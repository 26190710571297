import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBars from 'components/SearchBars';
import { 
  Spinner, NonIdealState
} from "@blueprintjs/core";
import Card from 'components/Card';
import { observer, inject } from 'mobx-react';
import QuotesTable from 'components/Tables/Quotes';
import Sticky from 'react-stickynode';
import styled from '@emotion/styled';

const CardHolder = styled('div')`
  padding: ${props => `${props.theme.unit*3}px`};
`
const PageWrapGray = styled('div')`
  padding:8px 12px 12px 12px;
  background-color: #F8FAFB;
  min-height: calc(100vh - 75px);
`

@inject('store', 'quoteStore', 'viewStore') @observer
class TechnicianQuotesPage extends Component {

  render() {
    let content;
    let {
      quoteStore: {
        quotes: {
          isLoading,
          isError
        }
      }
    } = this.props;
    if (isLoading && !isError) {
      content = <CardHolder><Spinner/></CardHolder>;
    }
    if (isError && !isLoading) {
      content = <NonIdealState title="Failed" description="Sorry, something went wrong" />;
    }
    if (!isError && !isLoading) {
      content = <QuotesTable />
    }
    return (
      <div>
        <Helmet>
          <title>Estimates | FieldNexus</title>
        </Helmet>
        <PageWrapGray>
        <Sticky enabled={true} top={0} innerZ={9}>          
            <SearchBars.Quote />          
        </Sticky>
        
          <Card>
            {content}
          </Card>
        
        </PageWrapGray>
      </div>
    )
  }
};

export default TechnicianQuotesPage;