import { observable, action } from 'mobx';
import { Api, CurrentEnv } from 'services/Api';
import stores from './index';
import moment from 'moment';
import Lockr from 'lockr';
import axios from 'axios';

class QuoteStore {

  @observable showAcceptQuoteLoader = false;
  @observable isGeneratingInvoice = false;

  @observable showLabourLineItemsLoader = false
  @observable showMaterialLineItemsLoader = false
  @observable showCustomLineItemsLoader = false
  @observable isLoadingBlankLineItem = false;
  @observable isaddedMaterialLineItem = false;
  @observable isLoadingBlankMaterialLineItem = false;
  @observable isLoadingBlankLabourLineItem = false;
  @observable isLoadingBlankCustomLineItem = false;
  @observable showUploadAttachmentLoader = false;

  @observable quotes = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable clientQuotes = {
    isLoading: false,
    isError: false,
    objects: [],
    meta: {}
  };

  @observable newEstimate = {
    isLoading: false,
    isError: false,
    object: {
      client_id:'',
      technician_id:'',
      scheduled_event_id:'',
      location_id:''
    }
  };

  @observable quote = {
    isLoading: false,
    isError: false,
    object: null
  };

  @observable sendQuote = {
    isLoading: false,
    isError: false,
    object: {
      emailTo: "",
      emailSubject: "",
      emailMessage: "",
      emailMessage: "",
      emailAttached: ""
    }
  };

  @observable contacts = {
    isLoading: false,
    isError: false,
    objects: []
  };


  @observable selectedContacts = [];

  @action
  generateInvoice(quote) {
    this.isGeneratingInvoice = true
    let promise = Api.post(`/work_reports/generate_invoice`, {work_report_id: quote.attributes.id})

    promise
      .then((response) => {
        this.isGeneratingInvoice = false
        // this.quote.object = response.data.data
        stores.viewStore.ShowInvoice({ uuid: response.data.data.id })
      })
      .catch((error) => {
        this.isGeneratingInvoice = false
      })

    return promise;
  }

  @action
  quoteAccept(quote_accept) {
    this.showAcceptQuoteLoader = true;
    let promise = Api.post(`/work_reports/${this.tenant_id}/quotes/${quote_accept.quote_id}/accept_quote`, { quote_accept: quote_accept })

    promise
      .then((response) => {
        this.showAcceptQuoteLoader = false;
      })
      .catch((error) => {
        this.showAcceptQuoteLoader = false;
      })

    return promise;
  }

  // update full quote with line_items
  // this.quote used as payload in this action
  @action
  update() {
    this.quote.isLoading = true;
    let promise = Api.put(`/work_reports/${this.quote.object.attributes.id}`, {work_report: this.quote.object.attributes})

    promise
      .then((response) => {
        this.quote.object = response.data.data;
        this.quote.isLoading = false;
        this.quote.isError = false;
      })
      .catch((error) => {
        this.quote.isLoading = false;
        this.quote.isError = true;
      })

    return promise;
  }

  @action
  SendQuoteToEmail() {
    const {
      viewStore: {
        currentUser: {
          attributes: {
            tenant_id
          }
        }
      }
    } = stores;
    const {
      quote: {
        object: {
          id
        }
      }
    } = this;
    this.quotes.isLoading = true;
    let promise = Api.post(`/work_reports/${tenant_id}/send_email/${id}/send_invoice_email`, { email_details: this.sendQuote.object })

     promise
      .then((response) => {
        this.quotes.isLoading = false;
        this.quotes.isError = false;
        stores.store.isOpen = false;
      })
      .catch((error) => {
        //console.error(error.response.data.errors.join(", "))
        this.quotes.isLoading = false;
        this.quotes.isError = true;
      })
    return promise;
  }

  @action
  getContacts(query) {
    this.contacts.isLoading = true;
    let promise = Api.post(`/contacts/search`, { term: query })

    promise
      .then((response) => {
        this.contacts.isLoading = false;
        this.contacts.isError = false;
        return response;
      })
      .catch((error) => {
        this.contacts.isLoading = false;
        this.contacts.isError = true;
      })
    return promise;
  }
  
  updateLineItem(lineItem) {
    let promise = Api.put(`/line_items/${lineItem.id}`, {line_item: lineItem})

    promise
      .then((response) => {
        //this.loadWorkReport()
      })
      .catch((error) => {
      })

    return promise;
  }

  @action
  loadQuotes() {
    this.quotes.isLoading = true;
    const {
      per_page,
      page,
      order,
      where
    } = stores.viewStore.quoteSearch;
    let promise = Api.post('/work_reports/search', { 
      search: { 
        where: where,
        order: order,
        page: page,
        per_page: per_page
      }
    });

    promise
      .then((response) => {
        this.quotes.isLoading = false;
        this.quotes.isError = false;
        this.quotes.objects = response.data.data;
        this.quotes.meta = response.data.meta;
      })
      .catch((error) => {
        this.quotes.isLoading = false;
        this.quotes.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadClientQuotes() {
    this.clientQuotes.isLoading = true;
    let promise = Api.post('/work_reports/search', { 
      search: window.stores.viewStore.clientQuoteSearch
    });

    promise
      .then((response) => {
        this.clientQuotes.isLoading = false;
        this.clientQuotes.isError = false;
        this.clientQuotes.objects = response.data.data;
        this.clientQuotes.meta = response.data.meta;
      })
      .catch((error) => {
        this.clientQuotes.isLoading = false;
        this.clientQuotes.isError = true;
        console.error(error)
      })
    
    return promise;
  }

  @action
  loadQuote(params) {
    this.quote.isLoading = true;
    let promise = Api.get(`/work_reports/${params.uuid}`)

    const onSuccess = (response) => {
      this.quote.isLoading = false;
      this.quote.object = response.data.data;
      stores.viewStore.currentUser.attributes.role === 'technician' ? stores.viewStore.showTechnicianQuote(params,response.data.data.attributes.number) : stores.viewStore.ShowQuote(params,response.data.data.attributes.number);
    }

    const onError = (error) => {
      this.quote.isLoading = false;
      this.quote.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  deleteQuote(quote) {
    this.quote.isLoading = true;
    let promise = Api.delete(`/work_reports/${quote.attributes.id}`)
    
    promise
      .then((response) => {
        this.loadQuotes();
      })
      .catch((error) => {
        this.quote.isLoading = false;
        this.quote.isError = true;
      })
    
    return promise
  }

  

  @action
  addBlankLineItem(kind, quoteId) {
    let lineItem = {
      work_report_id: quoteId,
      kind: kind,
      work_date: moment()
    }
    let quote = {
      id: quoteId
    }

    if(kind === 'labour') {
      this.showLabourLineItemsLoader = true;
    } else if(kind === 'material') {
      this.showMaterialLineItemsLoader = true;
    } else if(kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: quote})

    promise
      .then((response) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
        this.update()
      })
      .catch((error) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
      })

  }

  @action
  addLineItem(material, workReportId, kind) {
    let lineItem = {
      work_report_id: workReportId,
      is_overtime:false,
      markup:material.attributes.mark_up,
      override:false,
      part_number:material.attributes.part_number,
      product_service_id:material.attributes.id,
      quantity:1,
      quantity_display_value:1,
      rate_per_unit:material.attributes.unit_price,
      supplier_id:material.attributes.supplier_id,
      description:material.attributes.description,
      kind: kind,
      work_date: moment()
    }
    let work_report = {
      id: workReportId
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: work_report})

    promise
      .then((response) => {
        this.isaddedMaterialLineItem = true;
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.isLoadingBlankMaterialLineItem = false;
        this.isLoadingBlankLabourLineItem = false;
        this.isLoadingBlankCustomLineItem = false;
      })
      return promise;

  }

  @action
  addBlankLineItemForProduct(kind, quoteId) {
    
    let lineItem = {
      work_report_id: quoteId,
      kind: kind,
      work_date: moment()
    }
    let quote = {
      id: quoteId
    }

    if(kind === 'labour') {
      this.showLabourLineItemsLoader = true;
    } else if(kind === 'material') {
      this.showMaterialLineItemsLoader = true;
      this.isLoadingBlankLineItem = true;
    } else if(kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }

    let promise = Api.post(`/line_items`, {line_item: lineItem, work_report: quote})

    promise
      .then((response) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
        this.isLoadingBlankLineItem = false;
        this.update()
      })
      .catch((error) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
      })
      return promise;
  }

  @action
  deleteLineItem(lineItem) {

    if(lineItem.kind === 'material') {
      this.showLabourLineItemsLoader = true;
    } else if(lineItem.kind === 'labour') {
      this.showMaterialLineItemsLoader = true;
    } else if(lineItem.kind === 'custom') {
      this.showCustomLineItemsLoader = true;
    }
    let promise = Api.delete(`/line_items/${lineItem.id }` );
    
    promise
      .then((response) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
        this.update();
      })
      .catch((error) => {
        this.showLabourLineItemsLoader = false;
        this.showMaterialLineItemsLoader = false;
        this.showCustomLineItemsLoader = false;
      })
    
    return promise
  }

  @action
  changeStatus(quote, event) {
    this.quote.isLoading = true;
    let promise = Api.post(`/work_reports/change_status`, {work_report: quote.attributes, event: event})

    const onSuccess = (response) => {
      this.quote.object = response.data.data;
      this.quote.isLoading = false;
      this.quote.isError = false;
    }

    const onError = (error) => {
      this.quote.isLoading = false;
      this.quote.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  changeTechnician(work_report, event) {
    this.quote.isLoading = true;
    let promise = Api.post(`/work_reports/update_created_by`, {id: work_report.id, event: event })

    const onSuccess = (response) => {
      this.quote.object = response.data.data;
      this.quote.isLoading = false;
      this.quote.isError = false;
    }

    const onError = (error) => {
      this.quote.isLoading = false;
      this.quote.isError = true;
    }

    promise
      .then(onSuccess)
      .catch(onError)

    return promise;
  }

  @action
  createEmpty() {
    this.newEstimate.isLoading = true
    let promise = Api.post(`/work_reports/create_empty`, {work_report: this.newEstimate.object})

    promise
      .then((response) => {
        this.newEstimate.isLoading = false
        this.quote.object = response.data.data
        this.newEstimate.technician_id = ''
        this.newEstimate.client_id = ''
        stores.viewStore.ShowQuote({ uuid: this.quote.object.attributes.id })
      })
      .catch((error) => {
        this.newEstimate.isLoading = false
      })

    return promise;
  }

  @action
  loadQuoteToReview() {
    this.quote.isLoading = true
    let promise = Api.get(`/work_reports/${this.tenant_id}/quotes/${this.work_report_id}`)

    promise
      .then((response) => {
        this.quote.isLoading = false;
        this.quote.isError = false;
        if(!Lockr.get('currentTenant')){
          Lockr.set('currentTenant', response.data.data.attributes.tenant);
        }
        this.quote.object = response.data.data
      })
      .catch((error) => {
        this.quote.isLoading = false;
        this.quote.isError = true;
      })

  }

  @action
  deleteLabourTaxLineItem(taxItem) {
    //var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (taxItem) {

      this.showLabourLineItemsLoader = true;
      let promise = Api.delete(`/line_items/labour_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.showLabourLineItemsLoader = false;
        this.update()
      }

      const onError = (error) => {
        this.showLabourLineItemsLoader = false;
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  deleteMaterialTaxLineItem(taxItem) {  
    var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (deleteTaxLineItem && taxItem) {

      this.showMaterialLineItemsLoader = true;
      let promise = Api.delete(`/line_items/material_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.showMaterialLineItemsLoader = false;
        this.update()
      }

      const onError = (error) => {
        this.showMaterialLineItemsLoader = false;
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  deleteCustomTaxLineItem(taxItem) {

    //var deleteTaxLineItem = window.confirm("Are you sure you want to delete this tax line item?");
    if (taxItem) {
      this.showCustomLineItemsLoader = true
      let promise = Api.delete(`/line_items/custom_tax_line_item/${taxItem.id }` );
      
      const onSuccess = (response) => {
        this.showCustomLineItemsLoader = false
        this.update()
      }

      const onError = (error) => {
        this.showCustomLineItemsLoader = false
      }

      promise
        .then(onSuccess)
        .catch(onError)

      return promise;
    } else {
      return new Promise(function(resolve, reject) {
        resolve();
    });
    }
  }

  @action
  uploadWorkReportAttachment(files, work_report_id = null) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file[]',files[i])
    };
    formData.append('work_report_id', work_report_id)
    this.showUploadAttachmentLoader = true;
    let promise = axios.post(`${CurrentEnv.domain()}/api/v2/work_reports/upload_attachments`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Api-Token': stores.viewStore.currentUser.attributes.api_token,
          'X-Tenant':stores.viewStore.currentUser.attributes.tenant_id,
          'X-Client-Identifier': 'React-Web'
        }
      }
    )

    promise
      .then((response) => {
        this.showUploadAttachmentLoader = false;
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = true;
      })

    return promise;

  }

  @action
  removeAttachment(attachment, work_report_id = null) {
    this.showUploadAttachmentLoader = true;
    let promise = Api.delete(`/work_reports/${attachment.id}/remove_attachment`)

    promise
      .then((response) => {
        if(work_report_id) {
          this.showUploadAttachmentLoader = false;
        }
      })
      .catch((error) => {
        this.showUploadAttachmentLoader = false;
      })

    return promise;
  }

};

export default QuoteStore;