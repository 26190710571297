import UsersMap from './users/map';
import UsersMapShow from './users/map_show';
import UsersIndex from './users/index';
import UsersShow from './users/show';
import UserProfile from './users/profile';
import ChangePassword from './users/change_password';
import UsersVacations from './users/vacations';
import InviteUsers from './users/invite';
import ProjectsIndex from './projects/index';
import ProjectsShow from './projects/show';
import ClientsIndex from './clients/index';
import SuppliersIndex from './suppliers/index';
import ClientShow from './clients/show';
import SuppliersShow from './suppliers/show';
import EquipmentsIndex from './equipments/index';
import EquipmentsShow from './equipments/show';
import FormsIndex from './forms/index';
import FormsSubmission from './forms/submission';
import FormShow from './forms/show';
import DashboardIndex from './dashboard/index';
import JobsIndex from './jobs/index';
import AddNewJob from './jobs/new';
import UnassignedJob from './jobs/unassigned_job';
import ShowJob from './jobs/show';
import WorkReportIndex from './work-reports/index';
import QuoteIndex from './quotes/index';
import WorkReportShowPage from './work-reports/show';
import WorkReportShowPurchaseOrderPage from './work-reports/show-purchase-order';
import QuoteShowPage from './quotes/show';
import TimesheetIndex from './timesheets/index';
import Login from './Login';
import Signup from './signup/signup';
import SignupCompany from './signup/signupCompany';
import SignupCompanyType from './signup/companyType';
import SignupCompanyTeamSize from './signup/teamSize';
import SignupDetails from './signup/details';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import InvalidResetPasswordToken from './InvalidResetPasswordToken';
import LoggedOut from './LoggedOut';
import NotFound from './NotFound';
import Invitation from './Invitation';
import InvitationAccepted from './InvitationAccepted';
import BillingIndexPage from './billing/index';
import InvoiceIndex from './invoices/index';
import InvoiceShowPage from './invoices/show';
import TaxesIndexPage from './taxes/index';
import PreferencesIndexPage from './preferences/index';
import ServiceTypeIndexPage from './service_types/index';
import ServiceTypeShowPage from './service_types/show';
import CompanyDetails from './preferences/company_details';
import DefaultLineItems from './preferences/line_items';
import InvoiceSettings from './preferences/invoice_settings';
import PayInvoice from './PayInvoice';
import ReviewQuote from './ReviewQuote';
import ServiceAgreementsIndex from './service_agreements/index';
import AccountingSetup from './preferences/accounting_setup';
import MaterialOrders from './work-reports/material-orders';
import PurchaseOrders from './work-reports/purchase-orders';
import ServiceAgreementShowPage from './service_agreements/show';
import PricingParameterIndexPage from './pricing_parameters/index';
import PricingParameterShowPage from './pricing_parameters/show';
import PricingParameterAddPage from './pricing_parameters/add';
import ServiceTypeExtraIndexPage from './service_type_extras/index';
import ServiceTypeExtraShowPage from './service_type_extras/show';
import ServiceTypeExtraAddPage from './service_type_extras/add';
import FrequencyIndexPage from './frequency/index';
import FrequencyShowPage from './frequency/show';
import FrequencyAddPage from './frequency/add';
import NewBooking from './bookings/new';
import BookingsIndexPage from './bookings/index';
import BookingShowPage from './bookings/show';
import BookingShowPublicPage from './bookings/showPublic';
import BookingNewPublicPage from './bookings/newPublic';
import BookingNewPublicModalPage from './bookings/newPublicModal';
import BookingConfirmationMessage from './BookingConfirmationMessage';
import SpotIndexPage from './spots/index';
import SpotShowPage from './spots/show';
import SpotAddPage from './spots/add';
import ProductsServicesIndexPage from './products_services/index';
import ProductCategoryIndexPage from './product_category/index';
import PaymentMethodIndexPage from './payment_methods/index';
import QuestionIndexPage from './questions/index';
import QuestionShowPage from './questions/show';
import QuestionAddPage from './questions/add';
import QuestionGroupIndexPage from './question_groups/index';
import QuestionGroupShowPage from './question_groups/show';
import QuestionGroupAddPage from './question_groups/add';
import BookingConfiguration from './bookings/booking_configuration';
import NotificationIndexPage from './notifications/index';
import ServiceTypeCategoryIndexPage from './service_type_category/index';
import AssignmentsIndex from './assignments/index';
import TechnicianQuotesPage from './technician/quotes';
import TechnicianQuoteShowPage from './technician/quoteShow';

const Users = {
  Index: UsersIndex,
  Show: UsersShow,
  Invite: InviteUsers,
  Map: UsersMap,
  MapShow: UsersMapShow,
  Vacations: UsersVacations,
  Profile: UserProfile,
  ChangePassword: ChangePassword
}

const Equipments = {
  Index: EquipmentsIndex,
  Show: EquipmentsShow
}

const Projects = {
  Index: ProjectsIndex,
  Show: ProjectsShow
}

const Clients = {
  Index: ClientsIndex,
  Show: ClientShow
}

const Suppliers = {
  Index: SuppliersIndex,
  Show: SuppliersShow
}

const Forms = {
  Index: FormsIndex,
  Show: FormShow,
  Submission: FormsSubmission
}

const Dashboard = {
  Index: DashboardIndex
}

const WorkReports = {
  Index: WorkReportIndex,
  Show: WorkReportShowPage,
  MaterialOrders: MaterialOrders,
  PurchaseOrders: PurchaseOrders,
  ShowPurchaseOrder: WorkReportShowPurchaseOrderPage
}


const Quotes = {
  Index: QuoteIndex,
  Show: QuoteShowPage
}

const Invoices = {
  Index: InvoiceIndex,
  Show: InvoiceShowPage
}

const Timesheets = {
  Index: TimesheetIndex
}

const Jobs = {
  Index: JobsIndex,
  AddNew: AddNewJob,
  Show: ShowJob,
  UnassignedJob: UnassignedJob
}

const Assignments = {
  Index: AssignmentsIndex
}

const Billing = {
  Index: BillingIndexPage
}

const Taxes = {
  Index: TaxesIndexPage
}

const PaymentMethod = {
  Index: PaymentMethodIndexPage
}

const ServiceType = {
  Index: ServiceTypeIndexPage,
  Show: ServiceTypeShowPage
}

const PricingParameter = {
  Index: PricingParameterIndexPage,
  Show: PricingParameterShowPage,
  Add: PricingParameterAddPage
}

const Question = {
  Index: QuestionIndexPage,
  Show: QuestionShowPage,
  Add: QuestionAddPage
}

const QuestionGroup = {
  Index: QuestionGroupIndexPage,
  Show: QuestionGroupShowPage,
  Add: QuestionGroupAddPage
}

const ServiceTypeExtra = {
  Index: ServiceTypeExtraIndexPage,
  Show: ServiceTypeExtraShowPage,
  Add: ServiceTypeExtraAddPage
}

const Frequency = {
  Index: FrequencyIndexPage,
  Show: FrequencyShowPage,
  Add: FrequencyAddPage
}

const Spot = {
  Index: SpotIndexPage,
  Show: SpotShowPage,
  Add: SpotAddPage
}

const Preferences = {
  Index: PreferencesIndexPage,
  DefaultLineItems: DefaultLineItems,
  InvoiceSettings: InvoiceSettings,
  CompanyDetails: CompanyDetails,
  AccountingSetup: AccountingSetup
}

const ServiceAgreements = {
  Index: ServiceAgreementsIndex,
  Show: ServiceAgreementShowPage
}

const ProductsServices = {
  Index: ProductsServicesIndexPage
}

const ProductCategory = {
  Index: ProductCategoryIndexPage
}

const Bookings = {
  Index: BookingsIndexPage,
  New: NewBooking,
  ShowPublic: BookingShowPublicPage,
  NewPublic: BookingNewPublicPage,
  NewPublicModal: BookingNewPublicModalPage,
  Show: BookingShowPage,
  BookingConfiguration: BookingConfiguration,
}

const Notifications = {
  Index: NotificationIndexPage
}

const ServiceTypeCategory = {
  Index: ServiceTypeCategoryIndexPage
}

const Technician = {
  Quotes: TechnicianQuotesPage,
  QuoteShow: TechnicianQuoteShowPage
}

const Pages = {
  Dashboard,
  Users,
  Projects,
  Clients,
  Suppliers,
  WorkReports,
  Quotes,
  Timesheets,
  Login,
  Signup,
  SignupCompany,
  SignupCompanyType,
  SignupCompanyTeamSize,
  SignupDetails,
  ForgotPassword,
  ResetPassword,
  InvalidResetPasswordToken,
  LoggedOut,
  Jobs,
  NotFound,
  Forms,
  Equipments,
  Invitation,
  InvitationAccepted,
  Billing,
  Invoices,
  Taxes,
  Preferences,
  ServiceType,
  PricingParameter,
  Question,
  QuestionGroup,
  ServiceTypeExtra,
  Frequency,
  Spot,
  PayInvoice,
  ServiceAgreements,
  Bookings,
  BookingConfirmationMessage,
  ProductsServices,
  ProductCategory,
  ReviewQuote,
  PaymentMethod,
  Notifications,
  ServiceTypeCategory,
  Assignments,
  Technician,
}

export default Pages;