import React, { Component } from 'react';
import { FocusStyleManager } from "@blueprintjs/core";
import { Helmet } from 'react-helmet';
import { Provider, observer, inject } from 'mobx-react';
import Pages from './pages';
import stores from './stores';
import startRouter from './Router';
import AppOfflineBanner from './components/AppOfflineBanner';
import { ThemeProvider } from '@emotion/react';
import Theme from './styles/Theme';
import Lockr from 'lockr';
import { 
  Button
} from "@blueprintjs/core";
import InternalPage from './components/InternalPage';
import posed from 'react-pose';
import TiltYourPhonePopup from './helpers/TiltYourPhonePopup';
import "bootstrap/dist/css/bootstrap.css";
import { withOrientationChange, isMobileOnly } from 'react-device-detect';

// Lockr.prefix = 'servicehq';
FocusStyleManager.onlyShowFocusOnTabs();
const router = startRouter(stores);

const Sidebar = posed.div({
  visible: { left: '0px' },
  hidden: { left: '-100%' },
  transition: {
    type: 'spring',
    stiffness: 200,
    damping: 0
  }
});


@inject('store', 'jobStore', 'serviceAgreementStore') @observer
class JobSidebar extends Component {

  onClose() {
    this.props.store.isOpen = false;
    this.props.jobStore.isServiceAgreement = false;
    this.props.jobStore.clearJob();
    this.props.serviceAgreementStore.clearServiceAgreement();
  }

  render() {
    return (
      <Sidebar
        className="box"
        pose={this.props.store.isOpen ? 'visible' : 'hidden'}
        tabIndex='0'
      >
        <Button 
          style={{ right: 0, position: 'absolute' }} 
          className="bp3-minimal"
          rightIcon='cross' 
          onClick={this.onClose.bind(this)} 
        />
        <Pages.Jobs.AddNew  />
      </Sidebar>
    )
  }
}

const renderCurrentView = (currentView) => {
  switch(currentView) {
    case 'Login': 
      return <Pages.Login />
    case 'Signup': 
      return <Pages.Signup />
    case 'SignupCompany': 
      return <Pages.SignupCompany />
    case 'SignupCompanyType': 
      return <Pages.SignupCompanyType />
    case 'SignupCompanyTeamSize': 
      return <Pages.SignupCompanyTeamSize />
    case 'SignupDetails': 
      return <Pages.SignupDetails />
    case 'Pages.Users.Index':
      return (
        <InternalPage>          
          <Pages.Users.Index />
        </InternalPage>
      )
    case 'Pages.Users.Invite':
      return <Pages.Users.Invite />
    case 'Pages.Dashboard.Index':
      return (
        <div>
          <InternalPage>
            <Pages.Dashboard.Index />
          </InternalPage>
        </div>
      )
    case 'LoggedOut': 
      return <Pages.LoggedOut />
    case 'Pages.Projects.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Projects.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkReports.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.WorkReports.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkReports.Show':
      return (
        <div>
          <InternalPage>
          <Pages.WorkReports.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkReports.ShowPurchaseOrder':
      return (
        <div>
          <InternalPage>
          <Pages.WorkReports.ShowPurchaseOrder />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkReports.MaterialOrders':
      return (
        <div>
          <InternalPage>
          <Pages.WorkReports.MaterialOrders />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkReports.PurchaseOrders':
      return (
        <div>
          <InternalPage>
          <Pages.WorkReports.PurchaseOrders />
          </InternalPage>
        </div>
      )
    case 'Pages.Quotes.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Quotes.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Quotes.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Quotes.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.Invoices.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Invoices.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Invoices.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Invoices.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.Timesheets.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Timesheets.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Jobs.AddNew':
      return (
        <div>
          <InternalPage>
          <Pages.Jobs.AddNew />
          </InternalPage>
        </div>
      )
    case 'Pages.Jobs.UnassignedJob':
      return (
        <div>
          <InternalPage>
          <Pages.Jobs.UnassignedJob />
          </InternalPage>
        </div>
      )
    case 'Pages.Jobs.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Jobs.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Assignments.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Assignments.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Jobs.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Jobs.Show />
          </InternalPage>
        </div>
      )
      case 'Pages.Clients.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Clients.Index />
          </InternalPage>
        </div>
      )
      case 'Pages.Suppliers.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Suppliers.Index />
          </InternalPage>
        </div>
      )
      case 'Pages.Suppliers.Show': 
      return (
        <div>
          <InternalPage>
          <Pages.Suppliers.Show />
          </InternalPage>
        </div>
      )
      case 'Pages.Clients.Show': 
      return (
        <div>
          <InternalPage>
          <Pages.Clients.Show />
          </InternalPage>
        </div>
      )
      case 'Pages.Users.Map': 
      return (
        <div>
          <InternalPage>
          <Pages.Users.Map />
          </InternalPage>
        </div>
      )
    case 'Pages.Users.MapShow': 
      return (
        <div>
          <InternalPage>
          <Pages.Users.MapShow />
          </InternalPage>
        </div>
      )
    case 'Pages.Users.Vacations': 
      return (
        <div>
          <InternalPage>
            <Pages.Users.Vacations />
          </InternalPage>
        </div>
      )
    case 'Pages.Users.Profile': 
      return (
        <div>
          <InternalPage>
          <Pages.Users.Profile />
          </InternalPage>
        </div>
      )
    case 'Pages.Users.ChangePassword': 
      return (
        <div>
          <InternalPage>
          <Pages.Users.ChangePassword />
          </InternalPage>
        </div>
      )
    case 'Pages.Forms.Index': 
      return (
        <div>
          <InternalPage>
          <Pages.Forms.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Forms.Show': 
      return (
        <div>
          <InternalPage>
          <Pages.Forms.Show />
          </InternalPage>
        </div>
      )
    case 'ForgotPassword':
      return <Pages.ForgotPassword />
    case 'ResetPassword':
      return <Pages.ResetPassword />
    case 'InvalidResetPasswordToken':
      return <Pages.InvalidResetPasswordToken />
    case 'Pages.Users.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Users.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.Equipments.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Equipments.Index />
          </InternalPage>
        </div>
      )

    case 'Pages.ServiceType.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ServiceType.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ServiceType.Show':
    return (
      <div>
        <InternalPage>
        <Pages.ServiceType.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.PricingParameter.Index':
      return (
        <div>
          <InternalPage>
          <Pages.PricingParameter.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.PricingParameter.Show':
    return (
      <div>
        <InternalPage>
        <Pages.PricingParameter.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.PricingParameter.Add':
    return (
      <div>
        <InternalPage>
        <Pages.PricingParameter.Add />
        </InternalPage>
      </div>
    )

    case 'Pages.Question.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Question.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Question.Show':
    return (
      <div>
        <InternalPage>
        <Pages.Question.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.Question.Add':
    return (
      <div>
        <InternalPage>
        <Pages.Question.Add />
        </InternalPage>
      </div>
    )
    case 'Pages.QuestionGroup.Index':
      return (
        <div>
          <InternalPage>
          <Pages.QuestionGroup.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.QuestionGroup.Show':
    return (
      <div>
        <InternalPage>
        <Pages.QuestionGroup.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.QuestionGroup.Add':
    return (
      <div>
        <InternalPage>
        <Pages.QuestionGroup.Add />
        </InternalPage>
      </div>
    )

    case 'Pages.ServiceTypeExtra.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ServiceTypeExtra.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ServiceTypeExtra.Show':
    return (
      <div>
        <InternalPage>
        <Pages.ServiceTypeExtra.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.ServiceTypeExtra.Add':
    return (
      <div>
        <InternalPage>
        <Pages.ServiceTypeExtra.Add />
        </InternalPage>
      </div>
    )
    case 'Pages.Spot.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Spot.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Spot.Show':
    return (
      <div>
        <InternalPage>
        <Pages.Spot.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.Spot.Add':
    return (
      <div>
        <InternalPage>
        <Pages.Spot.Add />
        </InternalPage>
      </div>
    )
    case 'Pages.Frequency.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Frequency.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Frequency.Show':
    return (
      <div>
        <InternalPage>
        <Pages.Frequency.Show />
        </InternalPage>
      </div>
    )
    case 'Pages.Frequency.Add':
    return (
      <div>
        <InternalPage>
        <Pages.Frequency.Add />
        </InternalPage>
      </div>
    )
    case 'Pages.Invitation':
      return <Pages.Invitation />
    case 'Pages.InvitationAccepted':
      return <Pages.InvitationAccepted />
    case 'Pages.Projects.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Projects.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.Equipments.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Equipments.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.WorkOrder.Show':
      return (
        <div>
          <InternalPage>
          <h3>work order will show here</h3>
          </InternalPage>
        </div>
      )
    case 'Pages.Billing.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Billing.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Taxes.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Taxes.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.PaymentMethod.Index':
      return (
        <div>
          <InternalPage>
          <Pages.PaymentMethod.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ProductsServices.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ProductsServices.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ProductCategory.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ProductCategory.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ServiceTypeCategory.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ServiceTypeCategory.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Preferences.Index':
      return (
        <div>
          <InternalPage>
          <Pages.Preferences.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.Bookings.Show':
      return (
        <div>
          <InternalPage>
          <Pages.Bookings.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.ServiceAgreements.Index':
      return (
        <div>
          <InternalPage>
          <Pages.ServiceAgreements.Index />
          </InternalPage>
        </div>
      )
    case 'Pages.ServiceAgreements.Show':
      return (
        <div>
          <InternalPage>
          <Pages.ServiceAgreements.Show />
          </InternalPage>
        </div>
      )
    case 'Pages.Preferences.CompanyDetails':
      return (
        <div>
          <InternalPage>
          <Pages.Preferences.CompanyDetails />
          </InternalPage>
        </div>
      )
      case 'Pages.Bookings.New':
        return (
          <div>
            <InternalPage>
            <Pages.Bookings.New />
            </InternalPage>
          </div>
        )
      case 'Pages.Bookings.Index':
        return (
          <div>
            <InternalPage>
            <Pages.Bookings.Index />
            </InternalPage>
          </div>
        )
      case 'Pages.Bookings.BookingConfiguration':
        return (
          <div>
            <InternalPage>
            <Pages.Bookings.BookingConfiguration />
            </InternalPage>
          </div>
        )
    case 'Pages.Preferences.DefaultLineItems':
      return (
        <div>
          <InternalPage>
          <Pages.Preferences.DefaultLineItems />
          </InternalPage>
        </div>
      )
      case 'Pages.Preferences.InvoiceSettings':
        return (
          <div>
            <InternalPage>
            <Pages.Preferences.InvoiceSettings />
            </InternalPage>
          </div>
        )
      case 'Pages.Preferences.AccountingSetup':
        return (
          <div>
            <InternalPage>
              <Pages.Preferences.AccountingSetup />
            </InternalPage>
          </div>
        )
    case 'Pages.Preferences.InvoiceSettings':
      return (
        <div>
          <InternalPage>
          <Pages.Preferences.InvoiceSettings />
          </InternalPage>
        </div>
      )
    case 'Pages.Notifications.Index':
      return (
        <div>
          <InternalPage>
            <Pages.Notifications.Index />
          </InternalPage>
        </div>
      )
    case 'PayInvoice':
      return <Pages.PayInvoice />
    case 'BookingShowPublicPage':
      return <Pages.Bookings.ShowPublic />
    case 'BookingNewPublicPage':
      return <Pages.Bookings.NewPublic />
    case 'BookingNewPublicModalPage':
      return <Pages.Bookings.NewPublicModal />
    case 'BookingConfirmationMessage':
      return <Pages.BookingConfirmationMessage />
    case 'ReviewQuote':
      return <Pages.ReviewQuote />  
    case 'FormSubmission':
      return <Pages.Forms.Submission />
    case 'Pages.Technician.Quotes':
      return (
        <div>
          <InternalPage>
            <Pages.Technician.Quotes />
          </InternalPage>
        </div>
      )
    case 'Pages.Technician.QuoteShow':
      return (
        <div>
          <InternalPage>
            <Pages.Technician.QuoteShow />
          </InternalPage>
        </div>
      )
    default:
      const viewName = currentView.split("Pages.")[1]
      const MyComponent = Pages[viewName];
      if(MyComponent){
        return (
          <div>
            <InternalPage>
              <MyComponent />
            </InternalPage>
          </div>
        )
      }else{
        return <Pages.NotFound />
      }
  }
}
@observer
class App extends Component {

  componentWillMount() {
    // document.body.style.backgroundColor = '#F9F9F9';
    document.body.style.backgroundColor = '#F5F7FA';
    document.body.className = 'bp3-ui-text';
    const publicRoutes = ['LoggedOut', 'ForgotPassword', 'ResetPassword', 'InvalidResetPasswordToken', 'Login', 'Signup', 'SignupCompanyType', 'SignupCompanyTeamSize', 'SignupDetails', 'Pages.Invitation', 'PayInvoice','ReviewQuote', 'FormSubmission', 'BookingShowPublicPage', 'BookingNewPublicPage', 'BookingNewPublicModalPage', 'BookingConfirmationMessage', 'InvitationAccepted'];
    if (!Lockr.get('currentUser') && !publicRoutes.includes(stores.viewStore.currentView)) {
      stores.viewStore.redirectToLoginScreen();
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    const { isPortrait } = this.props;
    return (
      <ThemeProvider theme={Theme}>
        {
          isMobileOnly && isPortrait &&  <TiltYourPhonePopup />
        }
        <Provider router={router} {...stores}>
          <React.Fragment>
            <Helmet>
              <title>{stores.viewStore.currentView || 'Loading..'} | Field Nexus</title>
            </Helmet>
            <AppOfflineBanner />
            <JobSidebar />
            {/* <UnassignedJobSidebar /> */}
            {
              renderCurrentView(stores.viewStore.currentView)
            }
          </React.Fragment>
        </Provider>
      </ThemeProvider>
    );
  }
}
App = withOrientationChange(App)

export {
  router,
  App
};
