import Navigo from 'navigo';
import Lockr from 'lockr';
import qs from 'qs';
import { Api } from './services/Api';
import Toast from './helpers/toaster';
import { Intent } from '@blueprintjs/core';

//let root = null;
let root = window.location.origin;
let useHash = false;
let hash = '#'

const startRouter = (stores) => {
  let router = new Navigo(root, useHash, hash);
  let processLoggedInBehaviour = {
    before: (done, params) => {
      const currentUser = Lockr.get('currentUser');
      if (currentUser) {
        if (currentUser.attributes?.role === 'technician') {
          stores.viewStore.technicianQuotes();
          router.navigate(`technician/quotes?${qs.stringify(stores.viewStore.quoteSearch, { encode: false })}`);
        } else {
          stores.viewStore.dashboard();
          router.navigate('/');
        }
        Toast.show({ message: "You are already logged in!", intent: Intent.DANGER })
        // done();
      } else {
        done();
      }
    }
  }

  let checkAndProcessTechnicianBehaviour = {
    before: (done, params) => {
      const currentUser = Lockr.get('currentUser');
      if (currentUser?.attributes?.role === 'technician') {
        stores.viewStore.technicianQuotes();
        router.navigate(`technician/quotes?${qs.stringify(stores.viewStore.quoteSearch, { encode: false })}`);
        // done();
      } else {
        done();
      }
    }
  }

  router
    .hooks({
      before: (done, params) => {
        stores.viewStore.routingParams = params;
        if (!stores.viewStore.currentUser) {
          stores.viewStore.setCurrentUser();
        }
        done();
      }
    })

  router
    .on('/', () => {
      // 
    }, {
      before: (done, params) => {
        const currentUser = Lockr.get('currentUser');
        if (currentUser) {
          if (currentUser.attributes?.role === 'technician') {
            stores.viewStore.technicianQuotes();
          } else {
            stores.viewStore.dashboard();
          }
          done();
        } else {
          router.navigate('/login');
        }
      }
    })
    .on('/login', () => {
      stores.viewStore.login();
    }, processLoggedInBehaviour)
    .on('/quickbooks/auth', () => {
      stores.viewStore.quickbooksAuth();
    })
    .on('/invitation-accepted/download', () => {
      stores.viewStore.invitationAccepted();
    })
    .on('/signup', () => {
      stores.viewStore.signup();
    }, processLoggedInBehaviour)
    .on('/signup/company', () => {
      stores.viewStore.signupCompany();
    }, processLoggedInBehaviour)
    .on('/signup/company/type', () => {
      stores.viewStore.signupCompanyType();
    }, processLoggedInBehaviour)
    .on('/signup/company/team', () => {
      stores.viewStore.signupCompanyTeamSize();
    }, processLoggedInBehaviour)
    .on('/signup/details', () => {
      stores.viewStore.signupDetails();
    }, processLoggedInBehaviour)
    .on('/invite/users', () => {
      stores.viewStore.inviteUsers();
    })
    .on('/logout', () => {
      stores.viewStore.performLogout();
    })
    .on('/logged-out', () => {
      stores.viewStore.performLogout();
    })
    .on('/forget-password', () => {
      stores.viewStore.forgetPassword();
    })
    .on('/password-reset/:token', (params, query) => {
      stores.viewStore.resetPassword(params);
    }, {
      before: (done, params) => {
        let promise = Api.post(`/password/verify_token`, {token: params.token})

        promise
          .then((response) => {
            done();
          })
          .catch((error) => {
            router.navigate('/password/expired');
          })
      }
    })
    .on('/password/expired', () => {
      stores.viewStore.invalidResetPasswordToken();
    })
    .on('/dashboard', (params, query) => {
      stores.viewStore.dashboardSearch = qs.parse(query);
      // stores.dashboard.loadUsers({ section: 'all' });
      // stores.dashboard.loadJobs();
      stores.viewStore.setCurrentUser();
      stores.viewStore.dashboard()
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/assignments', (params, query) => {
      stores.viewStore.assignments()
      stores.jobStore.loadAssignments(params, query)
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/jobs', (params, query) => {
      stores.viewStore.jobs()
      stores.jobStore.loadJobs(params, query)
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/jobs/new', (params, query) => {
      stores.viewStore.AddNewJob()
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/jobs/:uuid', (params, query) => {
      stores.viewStore.ShowJob(params, query);
      stores.jobStore.loadJob(params, query)
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/jobs/:uuid/:section', (params, query) => {
      stores.viewStore.ShowJob(params, query);
      stores.jobStore.loadJob(params, query)
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/map-show', (params, query) => {
      stores.viewStore.geolocationSearch = qs.parse(query);
      stores.viewStore.userMapShow();
      stores.userStore.loadUser({ uuid: stores.viewStore.geolocationSearch.where.user_id });
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/map', (params, query) => {
      stores.viewStore.userMap(params);
      stores.dashboard.loadUsers({section: 'all'});
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/vacations', (params, query) => {
      stores.viewStore.usersVacations(params);
      stores.userStore.loadUsersVacations();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/profile', (params, query) => {
      stores.viewStore.userProfile(params);
      stores.userStore.loadUser(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/change-password', (params, query) => {
      let currentUser = Lockr.get('currentUser');
      if(params === ''){
        stores.viewStore.changePassword(params);
        stores.userStore.loadUser(params);
      }else{
        stores.viewStore.changePassword(params);
        stores.userStore.loadUser({ uuid: currentUser.id });
      } 
    })
    .on('/dashboard/users/:section', (params, query) => {
      stores.viewStore.users(params);
      stores.dashboard.loadUsers(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/users/:uuid/show', (params, query) => {
      stores.viewStore.userShowSearch = qs.parse(query);
      stores.viewStore.userShow(params);
      stores.userStore.loadUser(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/users/invitation/:token', (params, query) => {
      stores.viewStore.userInvitation(params);
      stores.userStore.loadUserInvitation(params);
    }, processLoggedInBehaviour)
    .on('/dashboard/projects', (params, query) => {
      stores.viewStore.projectSearch = qs.parse(query);
      stores.viewStore.projects();
      stores.projectStore.loadProjects();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/projects/:uuid/show', (params, query) => {
      stores.viewStore.projectShowSearch = qs.parse(query);
      stores.viewStore.projectShow(params);
      stores.projectStore.loadProject(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/work-reports', (params, query) => {
      stores.viewStore.workReportSearch = qs.parse(query);
      stores.viewStore.workReports();
      stores.workReportStore.loadWorkReports();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/work-reports/:uuid/show', (params, query) => {
      stores.viewStore.ShowWorkOrder(params);
      stores.workReportStore.loadWorkReport(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/purchase-orders/:uuid/show-purchase-order', (params, query) => {
      stores.viewStore.ShowPurchaseOrder(params);
      stores.workReportStore.loadPurchaseOrder(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/quotes', (params, query) => {
      stores.viewStore.quoteSearch = qs.parse(query);
      stores.viewStore.quotes();
      stores.quoteStore.loadQuotes();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/quotes/:uuid/show', (params, query) => {
      stores.viewStore.ShowQuote(params);
      stores.quoteStore.loadQuote(params);
      stores.taxStore.loadTaxes();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/invoices', (params, query) => {
      stores.viewStore.invoiceSearch = qs.parse(query);
      stores.viewStore.invoices();
      stores.invoiceStore.loadInvoices();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/invoices/:uuid/show', (params, query) => {
      stores.viewStore.ShowInvoice(params);
      stores.invoiceStore.getInvoice(params);
      stores.taxStore.loadTaxes();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/clients', (params, query) => {
      stores.viewStore.clients();
      stores.clientStore.loadClients();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/suppliers', (params, query) => {
      stores.viewStore.suppliers();
      stores.supplierStore.loadSuppliers();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/suppliers/:uuid/show', (params, query) => {     
      stores.viewStore.supplierShow(params);
      stores.supplierStore.loadSupplier(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/equipments', (params, query) => {
      stores.viewStore.equipments();
      stores.equipmentStore.loadEquipments();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/equipments/:uuid/show', (params, query) => {
      stores.viewStore.equipmentShowSearch = qs.parse(query);
      stores.viewStore.equipmentShow(params);
      stores.equipmentStore.loadEquipment(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service-type', (params, query) => {
      stores.viewStore.serviceTypes();
      stores.serviceTypeStore.loadServiceTypes();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service-type/:uuid/show', (params, query) => {
      stores.viewStore.serviceTypeSearch = qs.parse(query);
      stores.viewStore.serviceTypeShow(params);
      stores.serviceTypeStore.loadServiceType(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service-type/add', (params, query) => {
      //stores.viewStore.serviceTypeSearch = qs.parse(query);
      stores.viewStore.serviceTypeShow(params);
      //stores.serviceTypeStore.loadServiceType(params);
    }, checkAndProcessTechnicianBehaviour)

    .on('/dashboard/pricing-parameter', (params, query) => {
      stores.viewStore.pricingParameters();
      stores.pricingParameterStore.loadPricingParameters();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/pricing-parameter/:uuid/show', (params, query) => {
      stores.viewStore.pricingParameterSearch = qs.parse(query);
      stores.viewStore.pricingParameterShow(params);
      // stores.pricingParameterStore.loadPricingParameter(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/pricing-parameter/add', (params, query) => {
      stores.viewStore.pricingParameterAdd(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question', (params, query) => {
      stores.viewStore.questions();
      stores.questionStore.loadQuestions();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question/:uuid/show', (params, query) => {
      stores.viewStore.questionSearch = qs.parse(query);
      stores.viewStore.questionShow(params);
      stores.questionStore.loadQuestion(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question/add', (params, query) => {
      stores.viewStore.questionAdd(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question-group', (params, query) => {
      stores.viewStore.questionGroups();
      stores.questionGroupStore.loadQuestionGroups();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question-group/:uuid/show', (params, query) => {
      stores.viewStore.questionGroupSearch = qs.parse(query);
      stores.viewStore.questionGroupShow(params);
      stores.questionGroupStore.loadQuestionGroup(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/question-group/add', (params, query) => {
      stores.viewStore.questionGroupAdd(params);
    }, checkAndProcessTechnicianBehaviour)
    
    .on('/dashboard/service-type-extra', (params, query) => {
      stores.viewStore.serviceTypeExtras();
      stores.serviceTypeExtraStore.loadServiceTypeExtras();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service-type-extra/:uuid/show', (params, query) => {
      stores.viewStore.serviceTypeExtraSearch = qs.parse(query);
      stores.viewStore.serviceTypeExtraShow(params);
      stores.serviceTypeExtraStore.loadServiceTypeExtra(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service-type-extra/add', (params, query) => {
      stores.viewStore.serviceTypeExtraAdd(params);
    }, checkAndProcessTechnicianBehaviour)

    .on('/dashboard/frequency', (params, query) => {
      stores.viewStore.frequencies();
      stores.frequencyStore.loadFrequencies();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/frequency/:uuid/show', (params, query) => {
      stores.viewStore.frequencySearch = qs.parse(query);
      stores.viewStore.frequencyShow(params);
      stores.frequencyStore.loadFrequency(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/frequency/add', (params, query) => {
      stores.viewStore.frequencyAdd(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/spots/add', (params, query) => {
      stores.viewStore.spotAdd(params);
    })

    .on('/dashboard/forms', (params, query) => {
      stores.viewStore.forms();
      stores.formStore.loadForms();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/forms/:uuid', (params, query) => {
      stores.viewStore.formShowSearch = qs.parse(query);
      stores.viewStore.formShow(params);
      stores.formStore.loadForm(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/clients/:uuid', (params, query) => {
      stores.viewStore.clientShowSearch = qs.parse(query);
      stores.viewStore.clientShow(params);
      stores.clientStore.loadClient(params);
      stores.clientStore.loadAnalytics(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/timesheets', (params, query) => {
      stores.viewStore.timesheetSearch = qs.parse(query);
      stores.viewStore.timesheets();
      stores.timesheetStore.loadTimelogs();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/materialorders', (params, query) => {
      stores.viewStore.materialLineItemSearch = qs.parse(query);
      stores.viewStore.MaterialOrder();
      // stores.materialorderStore.loadMaterialOrder();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/purchase-orders', (params, query) => {
      stores.viewStore.purchaseOrder = qs.parse(query);
      stores.viewStore.PurchaseOrder();
    }, checkAndProcessTechnicianBehaviour)
    .on('/billing', (params, query) => {
      stores.viewStore.billingInfo = qs.parse(query);
      stores.viewStore.loadBillingOverview();
    })
    .on('/taxes', (params, query) => {
      stores.viewStore.loadTaxes();
    })
    .on('/payment_methods', (params, query) => {
      stores.viewStore.loadPaymentMethods();
    })
    .on('/products_services', (params, query) => {
      stores.viewStore.loadProductsServices();
    })
    .on('/product_category', (params, query) => {
      stores.viewStore.loadProductCategory();
    })
    .on('/service_type_category', (params, query) => {
      stores.viewStore.loadServiceTypeCategory();
    })
    .on('/preferences', (params, query) => {
      stores.viewStore.loadPreferences();
    })
    .on('/preferences/default_line_items', (params, query) => {
      stores.viewStore.loadDefaultLineItems();
    })
    .on('/preferences/accounting_setup', (params, query) => {
      stores.viewStore.accountingSetup();
    })
    .on('/dashboard/service_agreements', (params, query) => {
      stores.viewStore.serviceAgreements();
      stores.serviceAgreementStore.loadServiceAgreements();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/service_agreements/:uuid/show', (params, query) => {
      stores.viewStore.serviceAgreementSearch = qs.parse(query);
      stores.viewStore.serviceAgreementShow(params);
      stores.serviceAgreementStore.loadServiceAgreement(params);
    }, checkAndProcessTechnicianBehaviour)
    .on('/preferences/invoice_settings', (params, query) => {
      stores.viewStore.invoiceSettings();
    })
    .on('/preferences/company_details', (params, query) => {
      stores.viewStore.companyDetails();
    })
    .on('/dashboard/bookings/:uuid/show', (params, query) => {
      stores.viewStore.bookingShow(params,query);
      stores.bookingStore.loadBooking(params)
      .then((response) => {
        stores.serviceTypeStore.loadServiceTypeDetail({uuid:response.data.data.attributes.service_type_id})
        .then((response) => {
          stores.serviceTypeStore.loadPricingParameter()
        });
      })
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/bookings', (params, query) => {
      stores.viewStore.bookingSearch = qs.parse(query);
      stores.viewStore.bookings();
      stores.bookingStore.loadBookings();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/bookings/booking-configuration', (params, query) => {
      stores.viewStore.bookingConfigurations();
    }, checkAndProcessTechnicianBehaviour)
    .on('/dashboard/bookings/new', (params, query) => {
      stores.viewStore.newBooking();
    }, checkAndProcessTechnicianBehaviour)
    .on('/pay/:tenant_id/invoices/:invoice_id', (params, query) => {
      stores.viewStore.payInvoice(params);
    })
    .on('/booking_public/:tenant_id/bookings/:booking_id/show', (params, query) => {
      stores.viewStore.showPublicBooking(params);
      stores.bookingStore.loadBookingForPublicLink(params)
    })
    .on('/booking_public/:tenant_id/bookings/new', (params, query) => {
      stores.viewStore.newPublicBooking(params);
      stores.bookingStore.loadTenantForPublicLink(params)
    })
    .on('/booking_public/:tenant_id/bookings/booking-modal', (params, query) => {
      stores.viewStore.newPublicModalBooking(params);
      stores.bookingStore.loadTenantForPublicModal(params)
    })
    .on('/booking-confirmation', (params, query) => {
      stores.viewStore.bookingConfirmationMessage(params);
    })
    .on('/review/:tenant_id/quotes/:work_report_id', (params, query) => {
      stores.viewStore.reviewQuote(params);
    })
    .on('/forms/:tenant_id/form_submission/:form_submission_id/:token', (params, query) => {
      stores.viewStore.formSubmission(params);
    })
    .on('/dashboard/notifications', (params, query) => {
      stores.viewStore.notifications()
      //stores.notificationStore.loadNotifications(params, query)
    }, checkAndProcessTechnicianBehaviour)
    .on('/technician/quotes', (params, query) => {
      stores.viewStore.quoteSearch = qs.parse(query);
      stores.viewStore.technicianQuotes()
      stores.quoteStore.loadQuotes();
    })
    .on('/technician/quotes/:uuid/show', (params, query) => {
      stores.viewStore.showTechnicianQuote(params);
      stores.quoteStore.loadQuote(params);
      stores.taxStore.loadTaxes();
    })
    .resolve()

  return router;
}

export default startRouter;