import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Card from "components/Card";
import Page from "components/Page";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  ControlGroup,
  AnchorButton,
  TextArea,
  Button,
  Alert,
  Intent,
} from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import styled from "@emotion/styled";
import ClientAutocomplete from "components/ClientAutocomplete";
import LocationAutocomplete from "components/LocationAutocomplete";
import ServiceTypePicker from "components/ServiceTypePicker";
import AddProjectModal from "components/Project/AddProjectModal";
import AddLocationModal from "components/Client/AddLocationModal";
import AddClientModal from "components/Client/AddClientModal";
import { observable } from "mobx";
import moment from "moment-timezone";
import RRuleGenerator from "components/react-rrule-generator";
import Toast from "helpers/toaster";
import "../../../node_modules/react-rrule-generator/build/styles.css";
// import RecurringJob from "components/ServiceAgreement/RecurringJob"

const CardHolder = styled("div")`
  padding: ${props => `${props.theme.unit * 3}px`};
  margin-bottom: 100px;
`;

@inject("store", "projectStore", "serviceAgreementStore", "jobStore")
@observer
class PersistServiceAgreement extends Component {
  @observable isOpenAddClientDialog = false;
  @observable isOpenAddProjectDialog = false;
  @observable isOpenAddLocationDialog = false;
  @observable isUpdateDialogVisible = false;
  @observable selectedLocation = null;
  @observable selectedClient = null;
  @observable minDateStart = moment().toDate();
  @observable startDateDefault = moment().toDate();
  @observable minDateEnd = moment().add(1, 'hour').toDate();
  @observable endDateDefault = moment().add(1, 'hour').toDate();

  componentDidMount() {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    if (object && !object.id) {
      object.start_date = this.startDateDefault;
      object.end_date = this.endDateDefault;
      object.start_time = moment().toDate();
      object.end_time = moment()
        .add(1, "hour")
        .toDate();
    }
  }

  onServiceTypeSelect(selectedOptions) {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    object.selectedServiceType = selectedOptions;
    if (selectedOptions.length > 0) {
      object.service_type_id = selectedOptions[0].value;
    }
  }

  handleCloseProjectDailog() {
    this.isOpenAddProjectDialog = false;
  }

  handleCloseClientDailog() {
    this.isOpenAddClientDialog = false;
  }

  handleCloseLocationDailog() {
    this.isOpenAddLocationDialog = false;
  }

  onLocationClear = () => {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    object.location_ids = [];
    object.locations = [];
    object.location = null;
  };

  onClientClear = () => {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    object.client_id = null;
    object.client = null;
    this.onLocationClear();
  };

  onSuccessAddClient(client) {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    this.isOpenAddClientDialog = false;
    object.client_id = client.id;
    object.client = client;
  }

  onSuccessAddLocation(location) {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    this.isOpenAddLocationDialog = false;
    object.location_id = location.id;
    object.location = location;
  }

  onSuccessAddProject() {
    this.isOpenAddProjectDialog = false;
  }


  onStartDateChange = time => {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    object.start_date = time;
    this.startDateDefault = time;

    if(object.start_date > this.minDateEnd){
      object.end_date = moment(time).add(1, 'hour').toDate();
      this.minDateEnd = moment(time).add(1, 'hour').toDate();
      this.endDateDefault = moment(time).add(1, 'hour').toDate();
    }else{
      object.end_date = moment(time).add(1, 'hour').toDate();
      this.minDateEnd = moment(time).add(1, 'hour').toDate();
    }
  };

  onEndDateChange = time => {
    let { object } = this.props.serviceAgreementStore.persistServiceAgreement;
    object.end_date = moment(time).toDate();
    this.endDateDefault = moment(time).toDate();
  };

  createServiceAgreement() {
    let {
      object
    } = this.props.serviceAgreementStore.persistServiceAgreement;
    if(object.title === '' || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title.', intent: Intent.DANGER })
      return;
    }
    if(object.reason_for_call === '' || object.reason_for_call === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter reason for call.', intent: Intent.DANGER })
      return;
    }
    if(object.status === '' || object.status === null || !object.status) {
      Toast.show({ icon: 'warning-sign', message: 'Please select status.', intent: Intent.DANGER })
      return;
    }
    
    this.props.serviceAgreementStore
      .create()
      .then(this.onSuccessfullCreateServiceAgreement.bind(this))
      .catch(this.onFailCreateServiceAgreement.bind(this));
  }

  onSuccessfullCreateServiceAgreement(response) {
    this.props.store.isOpen = false;
    this.props.jobStore.isServiceAgreement = false;
    Toast.show({
      icon: "thumbs-up",
      message: "Service Agreement created/updated successfully!",
      intent: Intent.SUCCESS
    });
  }

  onFailCreateServiceAgreement(error) {
    if (error.response && error.response.data && error.response.data.errors) {
      Toast.show({
        icon: "warning-sign",
        message: error.response.data.errors,
        intent: Intent.DANGER
      });
    } else {
      Toast.show({
        icon: "warning-sign",
        message:
          "Failed to create/update service agreement. Something went wrong!",
        intent: Intent.DANGER
      });
    }
  }

  handleUpdateConfirm() {
    this.isUpdateDialogVisible = false;
    let {
      object
    } = this.props.serviceAgreementStore.persistServiceAgreement;
    if(object.title === '' || object.title === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter title.', intent: Intent.DANGER })
      return;
    }
    if(object.reason_for_call === '' || object.reason_for_call === null) {
      Toast.show({ icon: 'warning-sign', message: 'Please enter reason for call.', intent: Intent.DANGER })
      return;
    }
    if(object.status === '' || object.status === null || !object.status) {
      Toast.show({ icon: 'warning-sign', message: 'Please select status.', intent: Intent.DANGER })
      return;
    }

    this.props.serviceAgreementStore
      .update()
      .then(this.onSuccessfullCreateServiceAgreement.bind(this))
      .catch(this.onFailCreateServiceAgreement.bind(this));
  }

  handleUpdateCancel() {
    this.isUpdateDialogVisible = false;
  }

  updateServiceAgreement() {
    this.isUpdateDialogVisible = true;
  }

  serviceAgreementDetails() {
    let {
      object,
      isLoading
    } = this.props.serviceAgreementStore.persistServiceAgreement;
    return (
      <CardHolder style={{ border: "0px" }}>
        <h3 className="bp3-heading">
          {object && object.id
            ? "Update Service Agreement"
            : "Add New Service Agreement"}{" "}
        </h3>
        <h6>Customer Information</h6>
        <div className="well job-client-name">
          <FormGroup
            label="Customer"
            labelFor="text-input"
            labelInfo="(enter customer name, phone number or email)"
          >
            <ControlGroup fill={true} vertical={false}>
              <ClientAutocomplete
                style={{ width: '400px'}}
                inputProps={{
                  placeholder:
                    "Start typing Customer Name, Phone number or Email.."
                }}
                value={object.client}
                onClientSelect={client => {
                  if (object && client) {
                    object.client_id = client.id;
                    object.client = client;
                  } else {
                    this.onClientClear();
                  }
                }}
              />
              <AnchorButton
                text="Add New"
                onClick={() => {
                  this.isOpenAddClientDialog = true;
                }}
                style={{ maxWidth: "100px", minHeight: "32px" }}
                className='add-new-btn'
              />
            </ControlGroup>
          </FormGroup>
          {object.client_id && (
            <FormGroup
              label="Location"
              labelFor="text-input"
              labelInfo="(search for location)"
            >
              <ControlGroup fill={true} vertical={false}>
                <LocationAutocomplete
                  scope={{ client_id: object.client_id }}
                  style={{ width: "400px" }}
                  inputProps={{
                    fill: true,
                    placeholder: "Start typing location.."
                  }}
                  onLocationClear={this.onLocationClear.bind(this)}
                  value={object.locations[0]}
                  onLocationSelect={location => {
                    if (object && location) {
                      object.location_ids = [location.id];
                      object.location = location;
                    } else {
                      this.onLocationClear();
                    }
                  }}
                />
                <AnchorButton
                  text="Add New"
                  onClick={() => {
                    this.isOpenAddLocationDialog = true;
                  }}
                  style={{ maxWidth: "100px", minHeight: "32px" }}
                  className='add-new-btn'
                />
              </ControlGroup>
            </FormGroup>
          )}
        </div>
        <h6>Job details</h6>
        <div className="well">
          <FormGroup label="Title" labelFor="text-input" labelInfo="(*)">
            <input
              type="text"
              className="bp3-input bp3-fill"
              placeholder="Title"
              defaultValue={object.title}
              onChange={event => {
                object.title = event.target.value;
              }}
            />
          </FormGroup>
          <FormGroup
            label="Reason for Call"
            labelFor="text-input"
            labelInfo="(*)"
          >
            <TextArea
              className="bp3-fill"
              placeholder='Write a short description of why you are scheduling this Job/Call?'
              value={object.reason_for_call || ''}
              onChange={(event) => { object.reason_for_call = event.target.value } } 
            />
          </FormGroup>

          {
            !object.id && <FormGroup>
              <RRuleGenerator
                value={object.rrule}
                onChange={(rrule) => {object.rrule = rrule}}
                config={{
                  repeat: ['Yearly', 'Monthly', 'Weekly'],
                  end: ['Never', 'On date'],
                  hideStart: false,
                  hideEnd: false
                }}
              />
            </FormGroup>
          }
          <div className="row">
            <div className="col-md-4">
              <FormGroup
                label="Start Time"
                labelFor="text-input"
                labelInfo="(*)"
              >
                <TimePicker 
                  useAmPm={false}
                  onChange={(date) => {object.start_time = moment(date).toDate()}}
                  value={object.start_time}
                />
              </FormGroup>
            </div>

            <div className="col-md-4">
              <FormGroup
                label="End Time"
                labelFor="text-input"
                labelInfo="(*)"
              >
                <TimePicker 
                  useAmPm={false}
                  onChange={(date) => {object.end_time = moment(date).toDate()}}
                  value={object.end_time}
                />
              </FormGroup>
            </div>
          </div>

          {/* <RecurringJob 
            onChange={(rrule) => {object.rrule = rrule}}
          /> */}

          
          <FormGroup labelFor="text-input">
            <ServiceTypePicker
              selectedOptions={object.selectedServiceType}
              onChange={this.onServiceTypeSelect.bind(this)}
              maximumSelections={1}
            />
          </FormGroup>
        </div>

        <FormGroup label="Status" labelFor="text-input" labelInfo="(*)">
          <div className="bp3-select bp3-fill">
            <select
              id={"service-agreement-status"}
              defaultValue={object.status}
              onChange={event => {
                object.status = event.target.value;
              }}
            >
              <option value="">Select Status</option>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
          </div>
        </FormGroup>

        {object && !object.id && (
          <Button
            icon="floppy-disk"
            text="Create Service Agreement"
            intent="primary"
            onClick={this.createServiceAgreement.bind(this)}
            loading={isLoading}
          />
        )}
        {object && object.id && (
          <Button
            icon="floppy-disk"
            text="Update Service Agreement"
            intent="primary"
            onClick={this.updateServiceAgreement.bind(this)}
            loading={isLoading}
          />
        )}

        {/* Static HTML */}
        
      </CardHolder>
    );
  }

  render() {
    let {
      object
    } = this.props.serviceAgreementStore.persistServiceAgreement;
    let { isOpen } = this.props.store;

    if (!isOpen) {
      return null;
    }
    return (
      <div>
        <Helmet>
          <title>
            {object && object.id
              ? "Update Service Agreement"
              : "Add New Service Agreement"}
          </title>
        </Helmet>
        <Page style={{ padding: 0 }}>
          <Card>{this.serviceAgreementDetails()}</Card>
          <AddProjectModal
            isOpen={this.isOpenAddProjectDialog}
            client_id={object.client_id}
            onClose={this.handleCloseProjectDailog.bind(this)}
            onSuccess={this.onSuccessAddProject.bind(this)}
          />
          <AddLocationModal
            isOpen={this.isOpenAddLocationDialog}
            client_id={object.client_id}
            onClose={this.handleCloseLocationDailog.bind(this)}
            onSuccess={this.onSuccessAddLocation.bind(this)}
          />
          <AddClientModal
            isOpen={this.isOpenAddClientDialog}
            onClose={this.handleCloseClientDailog.bind(this)}
            onSuccess={this.onSuccessAddClient.bind(this)}
          />
          <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Update"
            icon="edit"
            intent={Intent.WARNING}
            isOpen={this.isUpdateDialogVisible}
            onCancel={this.handleUpdateCancel.bind(this)}
            onConfirm={this.handleUpdateConfirm.bind(this)}
          >
            <p>
              All associated future jobs will also be updated. Are you sure you want to do this?
            </p>
          </Alert>
        </Page>
      </div>
    );
  }
}

export default PersistServiceAgreement;
